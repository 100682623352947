<template>
  <div>
    <v-row>
      <v-col
        v-for="(summary, index) in countSummaryData"
        :key="index"
        cols="12"
        md="4"
      >
        <v-card>
          <v-card-title class="align-start d-flex align-center flex-wrap">
            <v-avatar
              size="40"
              rounded
              :color="summary.color"
              class="info--text me-3"
            >
              <v-icon
                size="24"
                color="white"
                class="rounded-0"
              >
                {{ summary.icon }}
              </v-icon>
            </v-avatar>
            <p class="d-flex align-center mb-0">
              {{ t(`${summary.name}s`) }}
            </p>
            <v-spacer></v-spacer>

            <v-btn
              small
              icon
              :to="{ name: 'apps-receipt-list' }"
            >
              <v-icon>
                {{ icons.mdiOpenInNew }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="pb-3">
            <!-- Today -->
            <v-list class="pt-1">
              <v-list-item class="d-flex px-0">
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-2">
                    <h4 class="font-weight-regular text-lg text-no-wrap">
                      {{ t('Today') }}
                    </h4>
                  </div>

                  <v-spacer></v-spacer>

                  <div class="d-flex align-center">
                    <span class="text-base font-weight-semibold text-2xl me-1">{{ summary.daily }}</span>
                  </div>
                </div>
              </v-list-item>
            </v-list>

            <!-- Last 7 days -->
            <v-list class="pt-1">
              <v-list-item class="d-flex px-0">
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-2">
                    <h4 class="font-weight-regular text-lg text-no-wrap">
                      {{ t('Last 7 days') }}
                    </h4>
                  </div>

                  <v-spacer></v-spacer>

                  <div class="d-flex align-center">
                    <span class="text-base font-weight-semibold text-2xl me-1">{{ summary.weekly }}</span>
                  </div>
                </div>
              </v-list-item>
            </v-list>

            <!-- Last 1 year -->
            <v-list class="pt-1">
              <v-list-item class="d-flex px-0">
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-2">
                    <h4 class="font-weight-regular text-lg text-no-wrap">
                      {{ t('Last 1 year') }}
                    </h4>
                  </div>

                  <v-spacer></v-spacer>

                  <div class="d-flex align-center">
                    <span class="text-base font-weight-semibold text-2xl me-1">{{ summary.yearly }}</span>
                  </div>
                </div>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="match-height">
      <!-- bar chart -->
      <v-col
        cols="12"
      >
        <v-card>
          <v-card-title class="d-flex align-center flex-wrap pb-0">
            <div
              class="d-flex align-center"
              :class="{ 'pb-5': $vuetify.breakpoint.smAndDown }"
            >
              <span>{{ t('Profit Breakdown') }}</span>
            </div>

            <v-spacer></v-spacer>

            <div class="d-flex flex-wrap align-center">
              <div
                class="d-flex align-center"
                :class="{ 'pb-5': $vuetify.breakpoint.smAndDown }"
              >
                <!-- Date range -->
                <v-menu
                  v-model="isDateMenuOpen"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :append-icon="icons.mdiCalendar"
                      :value="dateRangeText"
                      readonly
                      dense
                      outlined
                      hide-details
                      class="me-4"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateRange"
                    color="primary"
                    :first-day-of-week="1"
                    range
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      block
                      @click="dateRangeChange()"
                    >
                      {{ t('Set Dates') }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>

              <div
                class="d-flex align-center"
                :class="{ 'pb-5': $vuetify.breakpoint.smAndDown }"
              >
                <!-- filter grouping -->
                <v-select
                  v-model="groupingFilter"
                  :items="groupingOptions"
                  single-line
                  outlined
                  dense
                  hide-details
                  :placeholder="t('Select Grouping')"
                  :class="{ 'dashboard-filter me-3': $vuetify.breakpoint.smAndUp }"
                ></v-select>
              </div>

              <div
                class="d-flex align-center"
                :class="{ 'pb-5': $vuetify.breakpoint.smAndDown }"
              >
                <!-- filter view -->
                <v-select
                  v-model="viewFilter"
                  :items="viewOptions"
                  single-line
                  outlined
                  dense
                  hide-details
                  :placeholder="t('Select View')"
                  :class="{ 'dashboard-filter': $vuetify.breakpoint.smAndUp }"
                ></v-select>
              </div>
            </div>
          </v-card-title>

          <!-- chart -->
          <v-card-text class="mt-5 chart-wrapper">
            <bar-chart
              v-if="doneFetching"
              :width="barChartData.labels.length <= 10 ? 1000 : barChartData.datasets.length * 1500"
              :height="600"
              :data="barChartData"
              :options="chartjsConfig.barChart.options"
              :class="{ 'day-view-wrapper': viewFilter == 'Day' }"
            />
            <v-container
              v-else
              fill-height
              fluid
              style="height: 600px;"
            >
              <v-row justify="center">
                <v-col class="text-center">
                  <v-sheet class="d-flex flex-column justify-space-between align-center">
                    <v-progress-circular
                      :rotate="360"
                      :size="450"
                      :width="75"
                      :value="progressLoader"
                      color="primary"
                    >
                      <h2>{{ progressLoader }}% data gathering...</h2>
                    </v-progress-circular>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="match-height">
      <!-- line chart -->
      <v-col
        cols="12"
      >
        <v-card>
          <v-card-title class="d-flex align-center flex-wrap pb-0">
            <div
              class="d-flex align-center"
              :class="{ 'pb-5': $vuetify.breakpoint.smAndDown }"
            >
              <span>{{ t('Monthly Profit Summary') }}</span>
            </div>

            <v-spacer></v-spacer>

            <div class="d-flex flex-wrap align-center">
              <div
                class="d-flex align-center"
                :class="{ 'pb-5': $vuetify.breakpoint.smAndDown }"
              >
                <!-- filter months -->
                <v-menu
                  v-model="isMonthMenuOpen"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :append-icon="icons.mdiCalendar"
                      :value="monthRangeText"
                      readonly
                      dense
                      outlined
                      class="me-4"
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="monthRange"
                    type="month"
                    color="primary"
                    range
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      block
                      @click="monthRangeChange()"
                    >
                      {{ t('Set Dates') }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>

                <!-- filter branch -->
                <v-combobox
                  v-model="branchFilters"
                  :items="branches"
                  item-text="name"
                  single-line
                  multiple
                  outlined
                  dense
                  hide-details
                  clearable
                  :placeholder="t('Select Branch')"
                  :class="{ 'branch-filter': $vuetify.breakpoint.smAndUp }"
                ></v-combobox>
              </div>
            </div>
          </v-card-title>

          <!-- chart -->
          <v-card-text class="mt-5 chart-wrapper">
            <bar-chart
              v-if="doneFetching"
              :width="lineChartData.labels.length <= 10 ? 1000 : lineChartData.datasets.length * 1500"
              :height="600"
              :data="lineChartData"
              :options="chartjsConfig.barChart.options"
              :class="{ 'day-view-wrapper': viewFilter == 'Day' }"
            />
            <v-container
              v-else
              fill-height
              fluid
              style="height: 600px;"
            >
              <v-row justify="center">
                <v-col class="text-center">
                  <v-sheet class="d-flex flex-column justify-space-between align-center">
                    <v-progress-circular
                      :rotate="360"
                      :size="450"
                      :width="75"
                      :value="progressLoader"
                      color="primary"
                    >
                      <h2>{{ progressLoader }}% data gathering...</h2>
                    </v-progress-circular>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <div class="d-flex flex-md-row flex-column">
            <template v-for="(insurance, index) in insuranceSummaryData">
              <div
                :key="insurance.name"
                class="flex-grow-1"
              >
                <v-card-title class="pb-0">
                  <v-avatar>
                    <v-img
                      contain
                      max-height="30"
                      max-width="30"
                      :src="require(`@/assets/images/icons/project-icons/${insurance.icon}.png`)"
                      class="me-3"
                    ></v-img>
                  </v-avatar>
                  <span class="me-3">{{ insurance.name }}</span>
                </v-card-title>

                <v-card-text>
                  <v-list>
                    <v-list-item class="d-flex px-0">
                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <div class="me-auto pe-2">
                          <h4 class="font-weight-semibold">
                            {{ t('Total Customer Payment') }}
                          </h4>
                        </div>

                        <span
                          class="font-weight-semibold"
                          :class="checkValue(insurance.total_customer_payment) ? 'success--text' : 'error--text'"
                        >
                          {{ insurance.total_customer_payment || '$0.00' }}
                        </span>
                      </div>
                    </v-list-item>

                    <v-list-item class="d-flex px-0">
                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <div class="me-auto pe-2">
                          <h4 class="font-weight-semibold">
                            {{ t('Total Net Payment') }}
                          </h4>
                        </div>

                        <span
                          class="font-weight-semibold"
                          :class="checkValue(insurance.total_net_payment) ? 'success--text' : 'error--text'"
                        >
                          {{ insurance.total_net_payment || '$0.00' }}
                        </span>
                      </div>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item class="d-flex px-0">
                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <div class="me-auto pe-2">
                          <h4 class="font-weight-semibold">
                            {{ t('Total Profit') }}
                          </h4>
                        </div>

                        <span
                          class="font-weight-semibold"
                          :class="checkValue(insurance.total_profit) ? 'success--text' : 'error--text'"
                        >
                          {{ insurance.total_profit || '$0.00' }}
                        </span>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </div>

              <v-divider
                v-if="index + 1 !== insuranceSummaryData.length"
                :key="insurance.icon"
                class="my-sm-5 mx-5"
                :vertical="$vuetify.breakpoint.smAndUp"
              ></v-divider>
            </template>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiCalendar,
  mdiReceipt,
  mdiFileDocumentOutline,
  mdiCurrencyUsd,
  mdiOpenInNew,
} from '@mdi/js'
import {
  inject,
  ref,
  onMounted,
  computed,
  watch,
} from '@vue/composition-api'
import BarChart from '@/components/charts/BarChart.vue'
import chartjsConfig from './chartjsConfig'

export default {
  components: {
    BarChart,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')

    // Properties
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const doneFetching = ref(false)
    const countSummaryData = ref([])
    const barChartData = ref({})
    const lineChartData = ref({})
    const insuranceSummaryData = ref([])
    const dateRange = ref([`${yearNow}-${monthNow}-01`, `${yearNow}-${monthNow}-28`])
    const monthRange = ref([`${yearNow}-01`, `${yearNow}-${monthNow}`])
    const isDateMenuOpen = ref(false)
    const isMonthMenuOpen = ref(false)
    const viewFilter = ref('Week')
    const branchFilters = ref([])
    const viewOptions = ref(['Day', 'Week', 'Month'])
    const groupingFilter = ref('Branches')
    const groupingOptions = ref(['Branches', 'Accounts', 'Principles'])
    const progressInterval = ref({})
    const progressLoader = ref(0)

    // Methods
    const sanitizeCountSummaryData = data => {
      data.forEach((summary, index) => {
        summary.color = chartjsConfig.iconColors[index]
        switch (summary.name) {
          case 'Receipt':
            summary.icon = mdiReceipt
            break
          case 'Invoice':
            summary.icon = mdiFileDocumentOutline
            break
          default:
            summary.icon = mdiCurrencyUsd
        }
      })

      return data
    }
    const sanitizeChartData = data => {
      data.datasets.forEach((dataset, index) => {
        if (dataset.type === 'bar') {
          dataset.backgroundColor = chartjsConfig.chartColors[index]
          dataset.borderColor = 'transparent'
        }

        if (dataset.type === 'line') {
          dataset.fill = false
          dataset.borderColor = chartjsConfig.chartColors[index]
          dataset.pointBackgroundColor = chartjsConfig.chartColors[index]
          dataset.tension = 0
        }
      })

      return data
    }
    const fetchAnalytics = () => {
      progressInterval.value = setInterval(() => {
        if (progressLoader.value === 100) progressLoader.value = 0
        progressLoader.value += 1
      }, 500)
      doneFetching.value = false
      store
        .dispatch('fetchAnalytics', {
          view: viewFilter.value.toLowerCase(),
          grouping: groupingFilter.value.toLowerCase(),
          start_date: dateRange.value[0],
          end_date: dateRange.value[1] ? dateRange.value[1] : dateRange.value[0],
          branch_ids: branchFilters.value.map(branch => branch.id),
          start_month: monthRange.value[0],
          end_month: monthRange.value[1] ? monthRange.value[1] : monthRange.value[0],
        })
        .then(response => {
          const { data } = response.data
          countSummaryData.value = sanitizeCountSummaryData(data.count_summary)
          barChartData.value = sanitizeChartData(data.analytics)
          lineChartData.value = sanitizeChartData(data.profit)
          insuranceSummaryData.value = data.insurance_summary

          clearInterval(progressInterval.value)
          progressLoader.value = 0
          doneFetching.value = true
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while generating analytics report. Please refresh!')
        })
    }
    const dateRangeChange = () => {
      isDateMenuOpen.value = false
      fetchAnalytics()
    }
    const monthRangeChange = () => {
      isMonthMenuOpen.value = false
      fetchAnalytics()
    }
    const checkValue = value => value && value.charAt(0) !== '-'

    // Utils
    const formatDate = date => {
      if (!date) return null
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    }

    // Computed
    const dateRangeText = computed(() => {
      if (Date.parse(dateRange.value[0]) > Date.parse(dateRange.value[1])) {
        const [start, end] = dateRange.value
        dateRange.value[0] = end
        dateRange.value[1] = start
      }

      const startDateText = formatDate(dateRange.value[0])
      const showEndDate = dateRange.value[0] !== dateRange.value[1] && formatDate(dateRange.value[1])
      const endDateText = showEndDate ? ` - ${formatDate(dateRange.value[1])}` : ''

      return `${startDateText}${endDateText}`
    })
    const monthRangeText = computed(() => {
      if (Date.parse(monthRange.value[0]) > Date.parse(monthRange.value[1])) {
        const [start, end] = monthRange.value
        monthRange.value[0] = end
        monthRange.value[1] = start
      }

      const startDateText = monthRange.value[0]
      const showEndDate = dateRange.value[0] !== dateRange.value[1] && monthRange.value[1]
      const endDateText = showEndDate ? ` - ${monthRange.value[1]}` : ''

      return `${startDateText}${endDateText}`
    })
    const branches = computed(() => store.state.branches)

    // Watch
    watch([viewFilter, branchFilters, groupingFilter], () => fetchAnalytics())

    // Mounted
    onMounted(async () => fetchAnalytics())

    return {
      t,
      dayNow,
      chartjsConfig,
      doneFetching,
      countSummaryData,
      barChartData,
      lineChartData,
      insuranceSummaryData,
      isDateMenuOpen,
      isMonthMenuOpen,
      viewFilter,
      branchFilters,
      viewOptions,
      branches,
      groupingFilter,
      groupingOptions,
      dateRange,
      monthRange,
      dateRangeText,
      monthRangeText,
      dateRangeChange,
      monthRangeChange,
      progressLoader,
      checkValue,
      icons: {
        mdiCalendar,
        mdiOpenInNew,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  .branch-filter {
    max-width: 27rem;
  }

  .dashboard-filter {
    max-width: 12.2rem;
  }

  .chart-wrapper {
    width: 100%;
    overflow-x: scroll;
  }

  .day-view-wrapper{
    float: left;
  }
</style>
